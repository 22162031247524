.calendarContainer {
  background-color: #fff;
  border: 0;

  font-family: 'Poppins' !important;
}

.react-calendar__tile:disabled {
  background-color: #fff;
}

.react-calendar__tile {
  flex: 0 0 13% !important;
  margin: 2px !important;
}

.react-calendar__tile--active {
  background-color: rgb(46, 136, 221);
  color: #fff !important;
  border-radius: 20px;
}

.react-calendar__month-view__days__day--weekend {
  color: red;
}

.react-calendar__navigation__label {
  font-size: 18px !important;
}

.react-calendar__year-view .react-calendar__tile {
  padding: 1em 0.5em !important;
}
.react-calendar__decade-view .react-calendar__tile {
  padding: 1em 0.5em !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
  border-radius: 50%;
}
.react-calendar__navigation button:disabled {
  background: #fff;
}
.react-calendar__tile--now {
  background-color: #ece8ff;
  border-radius: 50%;
  color: black;
}
.react-calendar button:enabled:hover {
  border-radius: 50%;
  background-color: #ece8ff;
}
abbr[title] {
  text-decoration: none;
}
.react-calendar__navigation button:enabled:focus {
  background-color: #ece8ff;
  border-radius: 50%;
}
.css-1gqslp5-MuiPaper-root-MuiPopover-paper {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
