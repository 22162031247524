.jobList .quill {
  height: 100% !important;
  /* height: calc(100% - 40px) !important; */
  border-radius: 4px !important;
  background: #fff !important;
}
.jobList .ql-container {
  height: 80% !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  padding: 4px 0px;
}
.jobList .ql-editor::-webkit-scrollbar {
  width: 2px !important;
}
.jobList .ql-toolbar.ql-snow {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.jobDisplay .quill{
  max-height: 200px !important;
  overflow-y: hidden !important;
}
.jobDisplay .ql-toolbar.ql-snow {
  display: none !important;
}
.jobDisplay .ql-container{
  border: none !important;
}
.jobDisplay .ql-editor::-webkit-scrollbar-thumb {
  height:10px !important
}
.jobListEdit .ql-editor{
  max-height: 185px !important;
  overflow-y: auto;
}
.jobListEdit .ql-editor::-webkit-scrollbar{
  width: 2px !important;
}
.jobListEdit .ql-editor{
  font-size: 15px;
  font-family: 'Poppins';
  font-weight: 400 !important;
}
.jobList .ql-editor{
  font-size: 15px;
  font-family: 'Poppins';
  font-weight: 400 !important;
}
.jobDisplay .ql-editor{
  font-size: 15px;
  font-family: 'Poppins';
  font-weight: 400 !important;
}
