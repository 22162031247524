.job-title .ql-toolbar {
  background: #F9F9F9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-color: white;
  border-bottom: 1px solid #CED4FF !important;
  padding-right: 70px;
}

.job-title .ql-container {
  background: #F9F9F9;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 300px;
  border-color: white;
  /* border: 1px solid #e7e7e7; */
}
.job-title-edit .ql-editor{
  margin-right: 5px;
}

.job-title-edit .ql-container{
  background: #F9F9F9;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 200px;
  border-color: white;
}