@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* linear gradient border */
.grounded-radiants {
  border: 2px solid transparent;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(#2e88dd, #ad19d5) border-box;
  border-radius: 4px;
}

iframe {
  pointer-events: none;
}
.pdfIframe iframe {
  pointer-events: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.PhoneInput {
  border: 1px solid rgb(208, 208, 208);
  border-radius: 8px;
  height: 47px;
}

input[type='tel'] {
  background: transparent !important;
  border-color: transparent !important;
  height: 44px !important;
  font-size: 16px;
}

input[type='tel']:focus-visible {
  outline: none !important;
}

input[type='tel']:disabled {
  background: transparent !important;
  color: rgba(0, 0, 0, 0.38);
}

.PhoneInputCountryIcon {
  width: 36px !important;
  height: 20px !important;
}

.PhoneInputCountryIconImg {
  border-radius: 7px !important;
}

.PhoneInputCountryIcon--border {
  box-shadow: none !important;
  background-color: transparent !important;
}

select {
  width: calc(100% - 20px);
  border-color: transparent !important;
  height: 44px !important;
  font-size: 16px;
}

select:focus-visible {
  outline: none !important;
}

.ql-editor::-webkit-scrollbar {
  width: 4px !important;
}

textarea:focus-visible {
  outline: none !important;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #9ca7f8;
}

.Toastify__toast {
  padding: 0px !important;
  border-radius: 5px !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05) !important;
}

.Toastify__toast-body {
  padding: 0px !important;
  height: 64px !important;
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.Toastify__close-button {
  padding-top: 10px !important;
  padding-right: 10px !important;
}

.toast-error .Toastify__toast-icon {
  width: 60px !important;
  height: 100% !important;
  justify-content: center !important;
  align-items: center;
  background-color: #ffd9d8 !important;
}

.toast-sucess .Toastify__toast-icon {
  width: 60px !important;
  height: 100% !important;
  justify-content: center !important;
  align-items: center;
  background-color: #dfffd9 !important;
}

.css-rb4wob-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 10px !important;
  /* border: 1px solid #D0D0D0; */
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  font-size: 14px !important;
  font-weight: 400;
  height: 47px !important;
  padding: 9px 11px !important;
  border-radius: 8px !important;
  color: rgb(70, 70, 70) !important;
  border: 1px solid rgb(208, 208, 208) !important;
  background: rgb(249, 249, 249) !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  border: 0 !important;
  border-radius: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 20px !important;
  margin-top: 0 !important;
}

.ReactFlagsSelect-module_label__27pw9 {
  color: rgb(70, 70, 70) !important;
}


.pdfIframe iframe ::-webkit-scrollbar {
  width: 3px !important;
}
.pdfIframe iframe ::-webkit-scrollbar-track {
  border-radius: 12px !important;
  background: #ECE8FF !important;
}
.pdfIframe iframe ::-webkit-scrollbar-thumb {
  background-color: #A71DD4 !important;
  border-radius: 3px !important;
  height: 36px !important;
  cursor: pointer !important;
}
.react-pdf__Page__canvas{
  width:100% !important;
}
.react-pdf__message{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100%;
}



/* Hide Google's default dropdown */
.pac-container {
  display: none !important;
}

/* Style your custom dropdown */
.custom-suggestions {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  z-index: 2; /* Ensure your custom dropdown is above other elements */
  width: 100%;
}

.custom-suggestions li {
  padding: 8px;
  cursor: pointer;
}

.custom-suggestions li:hover {
  background-color: #f0f0f0;
}